.content-inner {
	.doc-section:first-child {
		padding-top: 0;
	}

	.doc-section {
		.section-title {
			&.small {
				font-size: 17px;
				padding-bottom: 7px;
			}
		}

		.section-block {
			padding-bottom: 0;
		}

		.code-block {
			margin-bottom: 10px;
			margin-top: 10px;
		}
	}
}

.box-params {
	li {
		padding: 3px 0;
	}
}

.page-wrapper {
	background: #f9f9fb;
}

pre.language-markup code.language-markup {
	width: 100%;
}
.section-block ul li {
	margin-bottom: 20px;
}
.section-block ul, .section-block ul li:last-child {
	margin-bottom: 0;
}

@media (max-width: 767px) {
	.doc-nav {position: relative; display: block}
}