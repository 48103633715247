/*
 * Template Name: PrettyDocs - Bootstrap 4 Template for documentations
 * Version: 2.0
 * Author: Xiaoying Riley
 * Copyright: 3rd Wave Media
 * Twitter: @3rdwave_themes
 * License: Creative Commons Attribution 3.0 License
 * Website: http://themes.3rdwavemedia.com/
*/

/*************************** Theme Colours ****************************/
$color-primary: #e7832d;
$color-green: #007ac7;
$color-red: #f77b6b;
$color-blue: #58bbee;
$color-orange: #F88C30;
$color-pink: #EA5395;
$color-purple: #8A40A7;


$text-color: #47484b;
$text-color-secondary: #616163;
$text-grey: #8b8e93;
$grey: lighten($text-color-secondary, 25%);
$light-grey: #c3c3c3;
$dark-grey: #666;
$black: #000;
$smoky-white: #f5f5f5;
$smoky-grey: #f9f9fb;
$divider: #f0f0f0;

$new: #60A823;
$error: #E65348;
$facebook: #3b5998;
$twitter: #55acee;
$google: #dd4b39;
$github: #444;

/*************************** Theme Styles ****************************/

@import "mixins";
@import "base";
@import "doc";
@import "landing";
@import "extra_amici";