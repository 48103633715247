/* ======= Doc Styling ======= */
.doc-wrapper {
    padding: 45px 0;
    background: $smoky-grey;
}

.doc-body {
    position: relative;
}

.doc-header {
    margin-bottom: 30px;
    .doc-title {
        color: $color-primary;
        margin-top: 0;
        font-size: 36px;
        .body-green & {
            color: $color-green;
        }
        .body-blue & {
            color: $color-blue;
        }
        .body-orange & {
            color: $color-orange;
        }
        .body-red & {
            color: $color-red;
        }
        .body-pink & {
            color: $color-pink;
        }
        .body-purple & {
            color: $color-purple;
        }
    }
    .icon {
        font-size: 30px;
    }
    .meta {
        color: $grey;
    }
}

.doc-content {
    //margin-left: 230px;
}


.doc-section {
    padding-top: 15px;
    padding-bottom: 15px;
    .section-title {
        font-size: 26px;
        margin-top: 0;
        margin-bottom: 0;
        font-weight: bold;
        padding-bottom: 10px;
        border-bottom: 1px solid darken($divider, 10%);
    }

    h1 {
        font-size: 24px;
        font-weight: bold;
    }
    h2 {
        font-size: 22px;
        font-weight: bold;
    }
    h3 {
        font-size: 20px;
        font-weight: bold;
    }
    h4 {
        font-size: 18px;
        font-weight: bold;
    }
    h5 {
        font-size: 16px;
        font-weight: bold;
    }
    h6 {
        font-size: 14px;
        font-weight: bold;
    }
}

.section-block {
    padding-top: 15px;
    padding-bottom: 15px;
    .block-title {
        margin-top: 0;
    }
    .list {
        &>li {
            margin-bottom: 10px;
        }
        ul {
            &> li {
                margin-top:5px;
            }
        }
    }

}

.question {

    font-weight: 400 !important;
    color: darken($color-primary, 5%);
    .body-green {
        color: darken($color-green, 5%);
    }
    .body-blue & {
        color: darken($color-blue, 5%);
    }
    .body-orange &{
        color: darken($color-orange, 5%);
    }
    .body-pink &{
        color: darken($color-pink, 5%);
    }
    .body-purple &{
        color: darken($color-purple, 5%);
    }
    .svg-inline--fa {
        @include opacity(0.6);
        position: relative;
        top: -2px;
    }
    .badge {
        font-size: 11px;
        vertical-align: middle;
    }
}

.answer {
    color: $text-color-secondary;
}

.code-block {
    margin-top: 30px;
    margin-bottom: 30px;
}

.callout-block {
    padding: 30px;
    @include border-radius(4px);
    position: relative;
    margin-bottom: 30px;
    a {
        color: rgba(0, 0, 0, 0.55) !important;
        &:hover {
            color: rgba(0, 0, 0, 0.65) !important;
        }
    }
    .icon-holder {
        font-size: 30px;
        position: absolute;
        left: 30px;
        top: 30px;
        color: rgba(0, 0, 0, 0.25);
    }
    .content {
        margin-left: 60px;
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .callout-title {
        margin-top: 0;
        margin-bottom: 5px;
        color: rgba(0, 0, 0, 0.65);
    }
}

.callout-info {
    background: $color-blue;
    color: #fff;
}

.callout-success {
    background: $color-green;
    color: #fff;
}

.callout-warning {
    background: $color-orange;
    color: #fff;
}

.callout-danger {
    background: $color-red;
    color: #fff;
}

.table>thead>tr>th {
    border-bottom-color: lighten($color-primary, 20%);
    .body-green & {
        border-bottom-color: lighten($color-green, 20%);
    }
    .body-blue & {
        border-bottom-color: lighten($color-blue, 20%);
    }
    .body-orange & {
        border-bottom-color: lighten($color-orange, 20%);
    }
    .body-pink & {
        border-bottom-color: lighten($color-pink, 20%);
    }
    .body-purple & {
        border-bottom-color: lighten($color-purple, 20%);
    }
}
.table-bordered>thead>tr>th {
    border-bottom-color: inherit;
}

.table-striped>tbody>tr:nth-of-type(odd) {
    background-color: $smoky-white;
}


.screenshot-holder {
    margin-top: 15px;
    margin-bottom: 15px;
    position: relative;
    text-align: center;
    img {
        border: 1px solid $divider;
    }
    .mask {
        display: block;
        visibility: hidden;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.25);
        cursor: pointer;
        text-decoration: none;
        .svg-inline--fa {
            color: #fff;
            font-size: 42px;
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            margin-left: -21px;
            margin-top: -21px;

        }
    }
    &:hover {
        .mask {
            visibility: visible;
        }
    }
}

.jumbotron {
    h1 {
        font-size: 28px;
        margin-top: 0;
        margin-bottom: 30px;
    }
}

.author-profile {
    margin-top: 30px;
    img {
        width: 100px;
        height: 100px;
    }
}

.speech-bubble {
    background: #fff;
    @include border-radius(4px);
    padding: 30px;
    margin-top: 20px;
    margin-bottom: 30px;
    position: relative;

    .speech-title {
        font-size: 16px;
    }

    .jumbotron  & {

        p {
            font-size: 14px;
            font-weight: normal;
            color: $text-color-secondary;
        }
    }

    &:before {
        content: "";
        display: inline-block;
        position: absolute;
        left: 50%;
        top: -10px;
        margin-left: -10px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #fff;

    }
}

.theme-card {
    text-align: center;
    border: 1px solid darken($divider, 5%);
    @include border-radius(4px);
    position: relative;
    height: 100%;

    .card-block {
        padding: 15px;
    }
    .mask {
        display: block;
        visibility: hidden;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.25);
        cursor: pointer;
        text-decoration: none;
        .icon {

            color: #fff;
            font-size: 42px;
            margin-top: 25%;
        }
    }
    &:hover {
        .mask {
            visibility: visible;
        }
    }
}


/* Color Schemes */
.body-green {
    .header {
        border-color: $color-green;
    }
    a {
        color: $color-green;
        &:hover {
            color: darken($color-green, 10%);
        }
    }
}
.body-blue {
    .header {
        border-color: $color-blue;
    }
    a {
        color: $color-blue;
        &:hover {
            color: darken($color-blue, 10%);
        }
    }
}

.body-orange {
    .header {
        border-color: $color-orange;
    }
    a {
        color: $color-orange;
        &:hover {
            color: darken($color-orange, 10%);
        }
    }
}

.body-pink {
    .header {
        border-color: $color-pink;
    }
    a {
        color: $color-pink;
        &:hover {
            color: darken($color-pink, 10%);
        }
    }
}

.body-purple {
    .header {
        border-color: $color-purple;
    }
    a {
        color: $color-purple;
        &:hover {
            color: darken($color-purple, 10%);
        }
    }
}

.body-red {
    .header {
        border-color: $color-red;
    }
    a {
        color: $color-red;
        &:hover {
            color: darken($color-red, 10%);
        }
    }
}






/* Sidebar */

.doc-sidebar {


}

.doc-nav {
	position:absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.sticky {
	position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
    top: 0;
}

.doc-menu {
    list-style: none;

   .nav-link {


        margin-bottom: 5px;
        display: block;
        padding: 5px 15px;
        color: $text-color-secondary;
        &:hover, &:focus {
            color: $text-color;
            text-decoration: none;
            background: none;

        }
        .nav {

        }
        &.active {

            background: none;
            color: $color-primary;
            font-weight: 600;
            .body-green & {
                color: $color-green;
                border-color: $color-green;
            }
            .body-blue & {
                color: $color-blue;
                border-color: $color-blue;
            }
            .body-orange & {
                color: $color-orange;
                border-color: $color-orange;
            }
            .body-red & {
                color: $color-red;
                border-color: $color-red;
            }
            .body-pink & {
                color: $color-pink;
                border-color: $color-pink;
            }
            .body-purple & {
                color: $color-purple;
                border-color: $color-purple;
            }



        }
    }
}
.doc-sub-menu {
    list-style: none;
    padding-left: 0;
    .nav-link {
        margin-bottom: 10px;
        font-size: 12px;
        &:first-child {
            padding-top: 5px;
        }

            display: block;
            color: $text-color-secondary;
            padding: 0;
            padding-left: 34px;
            background: none;
            &:hover{
                color: $text-color;
                text-decoration: none;
                background: none;
            }
            &:focus {
                background: none;
            }

        &.active {

                background: none;
                color: $color-primary;
                .body-green & {
                    color: $color-green;
                }
                .body-blue & {
                    color: $color-blue;
                }
                .body-orange & {
                    color: $color-orange;
                }
                .body-red & {
                    color: $color-red;
                }
                .body-pink & {
                    color: $color-pink;
                }
                .body-purple & {
                    color: $color-purple;
                }

        }
    }
}

/*
.affix-top {
  position: absolute;
  top: 15px;
}

.affix {
  top: 15px;
}

.affix,
.affix-bottom {
  width: 230px;
}

.affix-bottom {
  position: absolute;
}
*/

/* ===== Promo block ===== */
.promo-block {
    background: darken($color-primary, 5%);
    .body-green & {
        background: darken($color-green, 5%);
    }
    .body-blue & {
        background: darken($color-blue, 5%);
    }
    .body-orange &{
        background: darken($color-orange, 5%);
    }
    .body-pink &{
        background: darken($color-pink, 5%);
    }
    .body-purple &{
        background: darken($color-purple, 5%);
    }


    a {
        color: rgba(0, 0, 0, 0.6);
        font-weight: bold;
        &:hover {
            color: rgba(0, 0, 0, 0.7);
        }
    }
    .promo-block-inner {
        padding: 45px;
        color: #fff;
        @include border-radius(4px);

    }
    .promo-title {
        font-size: 20px;
        font-weight: 800;
        margin-top:0;
        margin-bottom: 45px;
        .svg-inline--fa {
            color: rgba(0, 0, 0, 0.6);
        }
    }
    .figure-holder-inner {
        background: #fff;
        margin-bottom: 30px;
        position: relative;
        text-align: center;
        img {
            border: 5px solid #fff;
        }
        .mask {
            display: block;
            visibility: hidden;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: rgba(0, 0, 0, 0.7);
            cursor: pointer;
            text-decoration: none;
            .svg-inline--fa {
                color: #fff;
                font-size: 36px;
                display: inline-block;
                position: absolute;
                top: 50%;
                left: 50%;
                margin-left: -18px;
                margin-top: -18px;
                &.pink {
                    color: $color-pink;
                }

            }
        }
        &:hover {
            .mask {
                visibility: visible;
            }
        }

    }

    .content-holder-inner {
        padding-left: 15px;
        padding-right: 15px;
    }

    .content-title {
        font-size: 16px;
        font-weight: 600;
        margin-top: 0;
    }

    .highlight {
        color: rgba(0, 0, 0, 0.6);
    }

    .btn-cta {
        background: rgba(0, 0, 0, 0.35);
        border: none;
        color: #fff !important;
        margin-bottom: 15px;
        &:hover {
            background: rgba(0, 0, 0, 0.5);
            border: none;
            color: #fff !important;
        }
    }
}

/* Extra small devices (phones, less than 768px) */
@media (max-width: 767px) {
     .doc-content {
         //margin-left: 0;
     }

     .jumbotron {
         padding: 30px 15px;
         h1 {
             font-size: 24px;
             margin-bottom: 15px;
         }
         p {
             font-size: 18px;
         }
     }
     .promo-block .promo-block-inner {
         padding: 30px 15px;
     }

     .promo-block .content-holder-inner {
         padding: 0;
     }
     .promo-block .promo-title {
         margin-bottom: 30px;
     }

}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {

}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}



.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.sticky:before,
.sticky:after {
    content: '';
    display: table;
}
.doc-center{
    display: flex;
    justify-content: center;
}
